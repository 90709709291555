
<template>
  <div class="Role_I">
    <div class="headNavs">
      <div class="headTilte">角色管理</div>
      <div class="headDetails">说明：角色管理</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>新建菜单</div>
      </div>
      <el-table
        :data="tableData"
        row-key="id"
        class="table ddddd"
        :indent="20"
        style="width: 100%"
        ref="multipleTable"
        @row-click="handleRowClick"
        header-cell-class-name="table-header"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @selection-change="select_change">
        <el-table-column align="left" prop="name" label="角色名称"></el-table-column>
        <el-table-column align="center" prop="title" label="角色值"></el-table-column>
        <el-table-column align="center" prop="pid" label="排序" ></el-table-column>
        <el-table-column align="center" prop="status" label="状态" >
          <template #default="scope" >
            <div v-if="scope.row.status == 'normal'" style="color:#b7eb8f">启用</div>
            <div v-if="scope.row.status == 'hidden'" style="color:#ffa39e">停用</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="备注"> </el-table-column>
        <el-table-column align="center" label="操作" width="350">
          <template #default="scope" >
              <div class="el_butto">
                <el-button
                type="text"
                @click="HandleEdit(scope.row)"
                >编辑</el-button>
              </div>
              <div class="el_butto rere">
                <el-button
                type="text"
                class="red"
                @click="HandleDel(scope.row)"
                >删除</el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>角色名称：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请输入角色名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>上级角色：</div>
            <div class="EditNewFormItemIpt">
              <el-cascader
              style="width:100%"
              v-model="Row_piii"
              :options="BMent"
              :props="{ value:'id', label:'name',checkStrictly: true, multiple: false }"
              @change="handleChange"></el-cascader>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="Holiday">
              <div>状态：</div>
              <div>
                <el-switch
                  v-model="Row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  inactive-color="#dcdfe6">
                </el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="Holiday">
              <div>菜单分配：</div>
              <div>
                <el-tree
                  :data="BMent_Role"
                  show-checkbox
                  node-key="id"
                  ref="tree"
                  :default-checked-keys="Row.rules"
                  :current-node-key="BMent_Role2"
                  @getCurrentKey-change = "handdddda"
                  @check-change="handleSelectionChange"
                  :props="defaultProps">
                </el-tree>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'  

export default {
  name: "basetable",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],
      BMent:[],
      BMent_Role:[],
      BRR:[],
      page:{currentPage:1,pageSize:50},
      Row:{
        pid: 1, 
        name:'',
        rules:[],
        status: "normal",
      },
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      total:'',
      titleMath:'',
      editId:'',
      Row_piii:'',
      Id_kddd:2,
      Id_kddd:2,
      addPanel:false,
      editPanel:false,
    };
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.getRule()
    that.Roletree()
  },
  methods: {
    loadTable(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/auth/group/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    Close(){
      this.editPanel = false
      this.addPanel = false
      this.Row = {}
    },
    getRule(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/auth/group/index',
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.BMent = res.data.data.rows
          }
      })
    },
    Roletree(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/auth/group/roletree',
        data: {
            id: that.Id_kddd,
            pid: that.Row.pid
        },
      }).then(function (res) {
          if (res.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.BMent_Role = res.data.data
          }
      })
    },
    showAdd(){
      var that = this
      that.addPanel = true
      that.titleMath = '新增角色'
    },
    BtnAdd(){
      var that = this
      that.Row.rules = that.BRR
      axios({
        method: 'post',
        url: '/admin/auth/group/add',
        data: {
          row:that.Row
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.addPanel = false
          that.Row = {}
          that.loadTable();
        }
      })
    },
    BtnEdit(){
      var that = this
      axios({
        method: 'PUT',
        url: '/admin/auth/group/edit',
        data: {
          id: that.editId,
          row:that.Row
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editPanel = false
          that.Row = {}
          that.loadTable();
        }
      })
    },
    HandleDel(data){
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/auth/group/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    HandleEdit(data){
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑角色'
      that.editId = data.id
      axios.get('/admin/auth/group/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row_piii = res.data.data.row.pid
          that.Row.pid = res.data.data.row.pid
          that.Row.title = res.data.data.row.title
          that.Row.name = res.data.data.row.name
          that.Row.id = res.data.data.row.id
          that.Row.rules = res.data.data.row.rules
        }
      })
    },
    handleSelectionChange(e) {
      var that = this
      var acc = that.$refs.tree.getCheckedKeys().concat(that.$refs.tree.getHalfCheckedKeys())
      that.BRR = acc
    },
  },

};
</script>

<style scoped>
.Holiday{
  display: flex;
}
.Holiday div:nth-child(1){
  margin-right: 30px;
}
</style>